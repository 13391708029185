/*---------------------
  NAVIGATION BAR
---------------------*/

.navbar {
  background: rgba(255, 255, 255, 1);
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* sticky */
  width: 100%;
  top: 0;
  z-index: 999;
  border-bottom: 3px solid rgb(50,50,50);
}

.navbar-container {
  display: flex;
  justify-content: left;
  align-items: start;
  width: 95%;
  height: 100%;
}

.navbar-logo {
  color: #fff;
  margin-left: 1vw;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  align-self: start;
  color: rgba(0, 0, 0, 1);
  height: 100%;
}

/* Style de l'image du logo */
.logo {
  display: block; /* Pour centrer l'image */
  width: 70px; /* 80 */
  height: 70px; /* 40 */
  margin: 0;
  margin-right: 10px;
  padding: 10px;
}

.nav-menu {
  display: flex;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  justify-content: end;
  margin-right: 2rem;
  margin-left: auto;
  align-self: center;
}

.nav-item {
  height: 100%;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  color: rgba(0, 0, 0, 1);
}

.nav-links:hover {
  transition: all 0.2s ease-out;
  color: rgba(167, 81, 252, 1);
}

.fa-bars {
  color: #333;
}

.nav-links-mobile {
  display: #fff;
  font-size: 0px;
}

.menu-icon {
  display: none;
  margin-left: auto;
}

.navbar-h3 {
  font-size: 25px;
}

.nav-item.dropdown {
  position: relative;
}

/* Drop down menu */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  background-color: #fff;
  padding: 0.5rem 0;
  min-width: 250px;
  width: 275px;
  list-style-type: none; /* Masquer les puces */
  border-radius: 0 0 4px 4px; /* Arrondir les bords inférieurs */
  box-shadow: 3px 3px rgba(0,0,0,0.5);

}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-link {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: rgb(50,50,50);
}

.dropdown-link:hover {
  background-color: #f1f1f1;
}

.nav-links i {
  font-size: 18px; /* Modifier la taille de la flèche vers le bas */
  padding-left: 6px;
}

.round-icon {
  border-radius: 50%; /* Faire l'icône rond */
  width: 20px; /* Changer la taille comme vous le souhaitez */
  height: 20px;
  margin-right: 5px; /* Ajoute un espace entre l'icône et le texte */
  padding-right: 15px;
  /* color: rgba(167, 81, 252, 1); */
}

/* Responsive */
@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 75px; /* 75px is the height of the navbar */
    left: 100%;
    opacity: 1;
    transition: all 0.5s ease;
    height: 300px;
    align-items: center; /* Change this line back to center */
    justify-content: flex-start; /* Add this line */
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    display: flex;
    text-align: center;
    align-self: center;
    justify-content: center;
    padding: 2rem;
    width: 100vw;
    color: #fff;
    transition: all 0.3s ease-in;
    height: 20px;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: relative;
    top: 0;
    right: 0;
    /*transform: translate(-100%, 60%);*/
    font-size: 40px;
    cursor: pointer;
    align-self: center;
    padding-right: 1vw;
  }

  .fa-times {
    color: #333;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background-color: #fff;
    color: #333;
    transition: 250ms;
  }

  .dropdown-menu {
    width: 100%;
    padding-left: 30%;
  }

  /*.logo {
    width: 80px;
  }*/
}

@media screen and (max-width: 600px) {

  .navbar-h3 {
    font-size: 0px;
    visibility: hidden;
  }
}