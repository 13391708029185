
.error_container {
    justify-content: space-evenly; /* center */
    height: 100vh;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: rgb(240, 240, 240);
    border-top: 3px solid rgb(50,50,50);
    /*background: linear-gradient(rgb(240,240,240), rgb(255,255,255));*/
    background-blend-mode: screen;  
    padding-top: 100px;
  }