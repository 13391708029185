.cards {
  padding: 4rem;
  background-color: rgb(240, 240, 240);
  border-bottom: 3px solid rgb(50,50,50);
  padding-bottom: 150px;
  /* border-top : 3px solid rgb(50,50,50); */
  padding-top: 0px;
}

.cards h1 {
  line-height: 2;
  width: 100%;
  justify-content: space-evenly;
  text-align: center;
}

.cards__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  max-width: 1500px;
}

.cards__wrapper {
  display: flex;
  position: relative;
  margin: 50px 0 45px;
  width: 100%;
  justify-content: center;
}

.cards__wrapper__left {
  justify-content: right;
}

.cards__wrapper__right {
  justify-content: left;
}

.cards__lateral {
  padding-top: 40px;
}

.cards__center {
  padding-top: 80px;
}

.cards__item {
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  width: 13vw;
  max-width: 200px;
  height: 250px;
  transition: transform 0.4s ease;
}

/* .cards__item:hover {
  transform: scale(1.1);
} */

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(0, 0, 0, 0.017));
  filter: drop-shadow(0 6px 20px rgba(0, 0, 0, 0.017));
  border-radius: 10px;
  text-decoration: none;
  align-self: center;
  justify-content: center;
  background-color: #fff;
}

.cards__item__pic-wrap {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  padding-bottom: 30px;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 6px 8px;
  padding-top: 10px;
  /* max-width: calc((100%) - 60px); */
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: rgba(185, 116, 252, 1);
  box-sizing: border-box;
}

.cards__item__img {
  height: 128px;
  width: 128px;
  transition: all 0.2s linear;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  /* background-color: red; */
  justify-content: center;
  padding: 0px;
}

.cards__item__info {
  padding-top: 20px;
}

.cards__item__info p{
  font-size: 18px;
  padding: 5px;
}

.cards__item__text {
  color: #252e48;
  display: flex;
  /*background-color: red;*/
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  text-align: center;
}

@media only screen and (max-width: 1520px) {
  .cards__item {
    width: 12vw;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1200px) {

  .cards__container {
    flex-direction: column;
  }
  
  .cards__wrapper {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    height: 300px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-evenly;
    margin: 0;
  }

  .cards__item {
    /*border: 5px solid rgb(50,50,50);*/
    position: relative;
    display: flex;
    cursor: pointer;
    border-radius: 8px;
    padding: 5px;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    height: 100%;
    object-fit: center;
    width: 40vw;
    max-width: none;
  }  

  /* .cards__item:hover {
    transform: scale(0.9);
  } */
  
  .cards__lateral {
    padding-top: 0px;
  }
  
  .cards__center {
    padding-top: 0px;
  }

}

@media only screen and (max-width: 600px) {

  .cards {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .cards__item {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  } 

}

@media only screen and (max-width: 400px) {

  .cards {
    padding: 5px;
    padding-bottom: 20px;
  }
  
  .cards__wrapper {
    flex-direction: column;
    height: 100%;
  }
  .cards__item {
    width: 100%;
  }  
  

}
