.devsquares_container {
    display: flex;
    flex-direction: column;
    background-color: rgb(240, 240, 240);
    width: 100%;
    text-align: center;
    justify-content: center;
    padding: 20px;
    padding-bottom: 100px;
    padding-top: 100px;
}

.devsquares_container h2 {
    text-align: left;
    align-self: center;
    width: 100%;
    padding-left: 10px;
    padding-bottom: 20px;
    max-width: 1500px;
}

.devsquares_wrapper {
    display: flex;
    flex-direction: row;
    margin: 10px;
    max-width: 1500px;
    width: 100%;
    align-self: center;

}

.devsquares_hbox {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 300px;
    width: 50%;
    margin: 10px;
    border: 1px solid rgb(50,50,50);
    padding: 20px;
    border-radius: 10px;
}

.devsquares_hbox a {
    text-align: left;
    color: rgba(175,125,250,1);
}

.devsquares_hbox span {
    color: rgb(90,90,90);
    font-weight: 500;
}

.devsquares_container h1 {
    padding: 50px;
}

.devsquares_container h4 {
    text-align: left;
    font-weight: 500;
    /* font-size: 24px; */
    padding-bottom: 15px;
}

.devsquares_icon {
    padding-right: 15px;
    color: rgba(175,125,250,1);
}

.devsquares_container p {
    text-align: left;
    /* font-size: 18px; */
    /* line-height: 1.5; */
}

.horizontal_box_devsquares {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-evenly;
    line-height: 50px;
    overflow-wrap: break-word;
}
  
.left_box_devsquares {
    flex: 1;
    position: relative;
    border-radius: 8px;
    padding:20px;
    align-self: center;
}

.right_box_devsquares {
    flex: 1;
    position: relative;
    border-radius: 8px;
    justify-content: center;
    object-fit: center;
    padding:20px;
}

.text_carousel_devsquares {
    color: white;
    height: 100%;
    width: 100%;
}


/*
@media only screen and (min-width: 1250px) {
    .devsquares_container h1 {
        font-size: 40px;
    }
    .devsquares_container h4 {
        font-size: 30px;
    }

}
*/

@media only screen and (max-width: 1250px) {

    .devsquares_container h1 {
        padding: 40px;
    }

    .devsquares_hbox {
        height: 350px;
    }
    
}

@media only screen and (max-width: 1100px) {

.devsquares_container {
    padding-top: 30px;
    padding-bottom: 30px;
}

.horizontal_box_devsquares {
    flex-direction: column;
}

.left_box_devsquares {
    align-self: stretch;
}

.devsquares_hbox {
    width: 100%;
    margin-top: 10px;
}
    
}

@media only screen and (max-width: 900px) {
.devsquares_hbox {
    height: 400px;
}   

.devsquares_hbox p {
    text-align: justify;
}    
}

@media only screen and (max-width: 700px) {

.devsquares_container {
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
}

.devsquares_wrapper {
    flex-direction: column;
    width: 100%;

}

.devsquares_container h1 {
    padding: 20px;
}

.devsquares_hbox {
    height: auto;
    width: auto;
}

}
