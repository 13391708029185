/* Timeline */
.timelineh1 {
  padding-bottom: 40px;
}

.myUl {
  --col-gap: 2rem;
  --row-gap: 2rem;
  --line-w: 0.25rem;
  display: grid;
  grid-template-columns: var(--line-w) 1fr;
  grid-auto-columns: max-content;
  column-gap: var(--col-gap);
  list-style: none;
  width: min(60rem, 90%);
  margin-inline: auto;  
}

/* line */
.myUl::before {
  content: "";
  grid-column: 1;
  grid-row: 1 / span 20;
  background: rgb(225, 225, 225);
  border-radius: calc(var(--line-w) / 2);
}

/* columns*/

/* row gaps */
.myUl .myLi:not(:last-child) {
  margin-bottom: var(--row-gap);
}

/* card */
.myUl .myLi {
  grid-column: 2;
  --inlineP: 1.5rem;
  margin-inline: var(--inlineP);
  grid-row: span 2;
  display: grid;
  grid-template-rows: min-content min-content min-content;
  background-color: #fff;
}

/* date */
.myUl .myLi .myDate {
  --dateH: 3rem;
  height: var(--dateH);
  margin-inline: calc(var(--inlineP) * -1);

  text-align: center;
  background-color: var(--accent-color);

  color: white;
  font-size: 1.25rem;
  font-weight: 600;

  display: grid;
  place-content: center;
  position: relative;

  border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}

/* date flap */
.myUl .myLi .date::before {
  content: "";
  width: var(--inlineP);
  aspect-ratio: 1;
  background: var(--accent-color);
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
  position: absolute;
  top: 100%;

  clip-path: polygon(0 0, 100% 0, 0 100%);
  right: 0;
}

/* circle */
.myUl .myLi .myDate::after {
  content: "";
  position: absolute;
  width: 2rem;
  aspect-ratio: 1;
  background: var(--bgColor);
  border: 0.3rem solid var(--accent-color);
  border-radius: 50%;
  top: 50%;

  transform: translate(50%, -50%);
  right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

/* title descr */
.myUl .myLi .myTitle,
.myUl .myLi .myDescr {
  background: var(--bgColor);
  position: relative;
  padding-inline: 1rem;
  line-height: 1.5;
}
.myUl .myLi .myTitle {
  overflow: hidden;
  padding-block-start: 1.5rem;
  padding-block-end: 1rem;
  font-weight: 500;
}
.myUl .myLi .myDescr {
  padding-block-end: 1.5rem;
  font-weight: 300;
}

/* shadows */
.myUl .myLi .timyTitletle::before,
.myUl .myLi .myDescr::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  left: 50%;
  border-radius: 50%;
  filter: blur(4px);
  transform: translate(-50%, 50%);
}
.myUl .myLi .myTitle::before {
  bottom: calc(100% + 0.125rem);
}

.myUl .myLi .myDescr::before {
  z-index: -1;
  bottom: 0.25rem;
}

@media (min-width: 40rem) {
  .myUl {
    grid-template-columns: 1fr var(--line-w) 1fr;
  }
  .myUl::before {
    grid-column: 2;
  }
  .myUl .myLi:nth-child(odd) {
    grid-column: 1;
  }
  .myUl .myLi:nth-child(even) {
    grid-column: 3;
  }

  /* start second card */
  .myUl .myLi:nth-child(2) {
    grid-row: 2/4;
  }

  .myUl .myLi:nth-child(odd) .myDate::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }

  .myUl .myLi:nth-child(odd) .myDate::after {
    transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  .myUl .myLi:nth-child(odd) .myDate {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }
}

.credits {
  margin-top: 1rem;
  text-align: right;
}
.credits a {
  color: var(--color);
}
