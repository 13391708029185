.test{
    background-color: red;
}

.parallax-profile-container {
    display: flex;
    flex-direction: column;
    position: relative;
    /* background-color: rgb(240, 240, 240); */
    justify-content: center;
}

.parallax-profile-container h4 {
    font-weight: 500;
    padding-bottom: 30px;
}

/* Intro */
.parallax-profile-transition {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    max-width: 1500px;
    margin-bottom: 30px;
    justify-content: center;
    text-align: center;  
    padding-top: 50px;
}

/* Quote */ 
.figcaption {
    text-align: right;
    width: 70%;
    position: absolute;
    bottom: 0;
    right: 5%;
    margin-right: 20px;
}
  
.figcaption:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #141414;
}
  
.figure-large {
    width: 100%;
    margin: 0px;
    padding: 0px;
}

.parallax-profile-quote-text {
    background-color: #fff;
    border: 1px solid rgb(50,50,50);
    height: 500px;
    border-radius: 5px;
}

.parallax-profile-quote-text span {
    background: #fff;
}

    
/* Content */
.parallax-profile-content {
    display: flex;
    flex-direction: row;
    align-self: center;
    max-width: 1500px;
    align-self: center;
    align-content: center;
    justify-content: center;
    /* height: 600px; */
}

.reverse-flex-row {
    flex-direction: row-reverse;
}

.break {
    color:white;
}

.parallax-profile-content-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    /* flex-basis: 33,33%;  */
    /* flex-grow: 0; ne se développe pas pour occuper plus d'espace */
    margin: 5px;
    width: 500px;
    height: 500px;
}

.parallax-profile-content img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border: 1px solid rgb(50,50,50);
    transform: rotateY(180deg);

}

/* Ending */
.parallax-profile-ending {
    display: flex;
    flex-direction: column;
    align-self: left;
    width: 100%;
    max-width: 1500px;
    margin-bottom: 30px;
    padding: 50px;
    justify-content: center;
    text-align: center;  
    align-self:center;
}

.parallax-profile-ending h1 {
    padding: 20px;
}

.parallax-profile-ending h3 {
    padding: 20px;
}

.parallax-profile-ending p {
    font-size: 18px;
    padding: 10px;
}

.parallax-profile-ending span {
    font-weight: 500;
    color: rgb(60,60,60);
}

.figcaption-span-services {
    background-color: rgba(255,255,255);
}

/* @media only screen and (max-width: 1400px) {
    .parallax-profile-content {
      height: 600px;
    }
}   */

@media only screen and (max-width: 1300px) {
    .parallax-profile-content-text p {
        font-size: 16px;
    }  
}


@media only screen and (max-width: 1200px) {

    .parallax-profile-quote-text p {
        font-size: 16px;
    }  
}
@media only screen and (max-width: 1000px) {
    .parallax-profile-content {
      flex-direction: column;
      height: auto;
      border: none;
      background-color: rgb(30,30,30);
      margin: 5px;

    }

    .parallax-profile-content-img {
        align-self: center;
        width: 80vw;
        height: 80vw;
   }

    .parallax-profile-content img {
        position: relative;
        height: 95%;
        width: auto;
    }

    .parallax-profile-quote-text {
        height: auto;
        margin: 0px;
        padding: 0px;
    }

   .parallax-profile-content-text p {
    font-size: 18px;
    }  
}  


