.services-container {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: rgb(240, 240, 240);
    justify-content: center;
}

.services-container h4 {
    font-weight: 500;
    padding-bottom: 30px;
}

.services-banner {
    height: 65vh;
    text-align: center;
    border-bottom: 3px solid rgb(50,50,50);
    position: relative;
    text-align: center;
    color: white;
    margin-bottom: 30px;
}

.services-figure {
    margin: 5px;
    width: 90%;
    /* background-color: yellow; */
    display: flex;
    align-self: center;
    height: 100%;
}

.services-banner img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: relative;
    text-align: center;
    color: white;
}

.advertise_link{
    color:red;
    font-weight: 500;
}

/* Intro */
.services-transition {
    display: flex;
    flex-direction: column;
    align-self: center;
    max-width: 1500px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: center;
    text-align: justify;
    width: 95%;
    /* border: 1px solid rgb(50,50,50); 
    background-color: blue; */
}

.services-transition p {
    margin: 20px;
    margin-left: 0px;
    margin-right: 0px;
}

.services-transition::after {
    height: 200px;
    width: 200px;
}

/* Quote */
.figure {
    display: flex;
    flex-direction: column;
    padding: 25px 60px;
    height: 100%;
    justify-content: center;
}

.blockquote {
    position: relative;
    background: url("/public/images/icones/quote-left-solid.png") no-repeat; 
    /* background-position: top 10px left 10px;  */
    /* background-position: center left 10px;  */
    background-position: top 50px left 10px; 
    background-size: 120px 120px; 
    padding-right: 0px;

    display: flex;
    flex-direction: column;
    align-self: center;
    padding: 20px;
    /* background-color: red; */
    margin: 5px;
}
  
.blockquote p {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 30px;
}

.text-italic p {
    font-style: italic;
}
  
/************/
.services-quote-text {
    width: 100%;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    align-self: center;
    /* flex-grow: 0; ne se développe pas pour occuper plus d'espace */
    padding: 30px;
    margin: 5px;
    text-align: center;
    flex-grow: 0; /* ne se développe pas pour occuper plus d'espace */
    background-color: none;
}

.services-quote-text p {
    position: relative;
}

.services-quote-text span {
    display: inline-block;
    position: relative;
    margin-right: 40px;
    padding: 0 8px;
    background: rgb(240,240,240);
    z-index: 1;
    font-weight: 500;
    color: rgb(60,60,60);
}

/* Content */
.services-content {
    display: flex;
    flex-direction: row;
    align-self: center;
    margin: 30px;
    width: 95%;
    max-width: 1500px;
    height: 550px;
    animation: fadeIn 3s;
}

.services-content-text {
    position: relative;
    width: 100%;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    flex-basis: 66.66%; /* occupe 2/3 de la largeur */
    flex-grow: 0; /* ne se développe pas pour occuper plus d'espace */
    padding: 30px;
    margin: 5px;
    background-color: white;
    border: 1px solid rgb(50,50,50);
}

.services-content-text span {
    color: rgb(60,60,60);
    font-weight: 600;
}

.services-content-text p {
    position: relative;
}

.services-content-first {
    margin-top: 0px;
}

.services-content-img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 33.33%; /* occupe 1/3 de la largeur */
    flex-grow: 0; /* ne se développe pas pour occuper plus d'espace */
    margin: 5px;
}

.services-content img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border: 1px solid rgb(50,50,50);
}

.services-content img:hover {
    object-fit: fill;
    /* border: 1px solid rgb(175,125,250); */
    filter:brightness(75%);
    cursor: pointer;
}

.services-content-left-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
  
.news-text {
    margin-left: 10px;
    /* font-weight: 600; */
    /* color: red; */
    padding-left: 15px;
    padding-right: 15px;
    /* font-style: italic; */
    /* color: rgba(175, 125, 250, 1); */
    color: #000;
    white-space: nowrap;
    /* text-shadow: 1px 1px 2px rgb(90,90,90); */
}

.services-content-ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
}

.services-icon {
    padding-right: 20px;
}

.subtitle-icons {
    padding-right: 5px;
}

.services-icon:hover {
    /* color: rgb(175, 125, 250, 1); */
    color: #000;
}

/* Start */
.services-start {
    display: flex;
    flex-direction: row;
    align-self: center;
    max-width: 1500px;
    width: 95%;
    animation: fadeIn 3s;
}

.services-start h3 {
    padding-bottom: 30px;
    padding-top: 50px;
}

@media only screen and (max-width: 1300px) {
    .services-content {
      height: 650px;
    }
} 

@media only screen and (max-width: 1000px) {
    .services-diagram p {
        font-size: 12px;
    }
}  

@media only screen and (max-width: 1000px) {
    .services-content {
      flex-direction: column;
      height: auto;
      border: none;
      background-color: rgb(30,30,30);
    }

    .services-content-text {
        margin:0px;
    }

    .services-content-img {
        /* width: 300px; */
        /* height: 20px; */
        align-self: center;
        object-fit: fill;
   }

   .reverse-flex-col {
       flex-direction: column-reverse;
   }
}  

@media only screen and (max-width: 750px) {

    /* .services-quote-text p {
        width: 110%;
        transform : translateX(-60px);
    }   */

    .services-diagram img {
        width: 64px;
        height: 64px;
    }

    .services-diagram p {
        width: 64px;
        font-size: 8px;
    }
}


@media only screen and (max-width: 400px) {

    .services-quote-text span {
        margin-right: 5px;
    }

}
