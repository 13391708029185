/* Diagram */
.services-diagram {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: 30px;
    width: 90%;
    max-width: 1500px;
    /* height: 500px; */
    background-color: #fff;
    border: 1px solid rgb(50,50,50);
    margin-bottom: 80px;
    margin-top: 0px;
    overflow: hidden;
    flex-wrap: wrap;    
}

.services-diagram-first {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: auto;
    /* background-color: red; */
}

.services-diagram-second {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: auto;
    /* background-color: red; */
}

.services-diagram-canvas {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    
}

.services-diagram-canvas p {
    margin-top: 10px;
    overflow-wrap: anywhere;
}

.services-diagram img {
    width: 92px;
    height: 92px;
    /* background-color: yellow; */
}

.services-diagram h3 {
    padding: 20px;
    font-weight: 600;
}

.services-diagram h4 {
    padding: 20px;
    padding-top: 5px;
}

.services-diagram-title2 {
    padding-top: 20px;
}

.services-diagram p {
    /* background-color: orange; */
    text-align: center;
    font-size: 16px;
    width: auto;
    line-height: 1;
    padding-bottom: 20px;
}

.datascience_button_wrapper {
    align-self: center;
    padding-bottom: 20px;
}



@media only screen and (max-width: 1000px) {

    .services-diagram {  
        overflow-x: scroll;
    }

}