.footer-container {
  background-color: rgb(50,50,50); /* #242424; */
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 3px solid rgb(50,50,50);
}

.fab {
  color: rgb(240,240,240);
}

.fab:hover {
  color: rgb(200,200,200);
}

.footer-links {
  width: 100%;
  /*max-width: 1000px;*/
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  /* width: 180px; */
  box-sizing: border-box;
}


.footer-link-items h2 {
  margin-bottom: 16px;
  font-size: 24px;
  color: rgb(240,240,240);
  word-break: break-all;
}

.logo-h2 {
  font-size: 24px;
  color: rgb(240,240,240)
}

.footer-link-items a {
  color: rgb(240,240,240);
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: rgb(200,200,200);
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: rgb(240,240,240);
  font-size: 24px;
  padding-left: 10px;
  padding-right: 10px;
}

.social-media {
  /*max-width: 1000px;*/
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 240px; */
}

.social-logo {
  color: rgb(240,240,240);
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: rgb(240,240,240);
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .social-media-wrap {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 400px) {
  .footer-container {
    font-size:12px;
  }

  .footer-link-items {
    width: 140px;
  }

}

@media screen and (max-width: 350px) {
  .footer-link-items {
    width: 120px;
  }
}

@media screen and (max-width: 300px) {
  .footer-link-items {
    width: 80px;
  }
}

@media only screen and (max-height: 500px) {
  .footer-links {
    padding-top: 0rem;
  }
}