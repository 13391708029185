.dashboards_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  text-align: center;
  justify-content: center;
  height: auto;
  margin-bottom: 100px;
}

/* Carousel */
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* ou une autre taille en px qui vous convient */
  grid-auto-rows: 300px; /* ou une autre taille en px qui vous convient */
  grid-gap: 10px; /* pour un petit espace entre les images */
  width: 90%;
  max-width: 1500px;
  height: auto;
  overflow: hidden; 
  /* border: 3px solid rgb(50,50,50);
  background-color: rgb(175,125,250,1); */
  padding: 10px;
}

.image-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative; /* pour positionner l'image correctement dans la cellule */
  width: 100%; /* pour que la cellule prenne toute la largeur disponible */
  height: 100%; /* pour que la cellule prenne toute la hauteur disponible */
  
}

.image-cell img {
  position: absolute; /* pour positionner l'image correctement dans la cellule */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid rgb(50,50,50);
}

.image-cell img:hover {
  filter:brightness(75%);
}


.no-scroll {
  overflow: hidden;
}
