:root {
  --primary: #fff;
}

.btn_testimonials {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: rgb(50,50,50);
  color: #fff;
  border: 1px solid rgb(50,50,50);
  width: 100%;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn_primary {
  background-color: #fff;
  color: #242424;
  border: 1px solid #fff;
}

.btn_secondary {
  background-color: rgb(60,60,60);
  color: #fff;
  border: 1px solid rgb(50,50,50);
}

.btn_third {
  background-color: rgb(60,60,60);
  color: #fff;
  border: 1px solid rgb(50,50,50);
}

.btn_third:hover {
  background-color: #fff;
  color: rgb(60,60,60);
  border: 1px solid rgb(60,60,60);
}

.btn_secondary:hover {
  background-color: #fff;
  color: rgb(60,60,60);
  border: 1px solid rgb(60,60,60);
}

.btn_outline {
  background-color: transparent;
  color: #242424;
  border: 1px solid #242424;
  transition: all 0.3s ease-out;
  width: 120px;
}

.btn-mobile {
  align-self: center;
  padding-right: 1vw;
}

.hero-btns {
  margin-top: 3vmin;
  animation: fadeIn_delayed 2s;
}

.hero-btns .btn {
  margin: 6px;
}

.btn_reverse {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 3px solid #fff;
  transition: all 0.3s ease-out;
}

.btn_medium {
  padding: 8px 20px;
}

.btn_large {
  padding: 12px 26px;
}

.btn_large2 {
  padding: 12px 26px;
}

.btn_large3 {
  /* font-size: 2vmin;
  font-size: 18px; */
}

.btn_outline:hover,
.btn_large:hover,
.btn_medium:hover {
  transition: all 0.3s ease-out;
  color: rgba(167, 81, 252, 1);
  border: 3px solid rgba(167, 81, 252, 1);
  background-color: #fff;
  transition: 250ms;
}

/* .btn_primary:hover {
  background-color: transparent;
} */

/* Testimonials button */
.modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  
}

.modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  width: 500px;
  border: 3px solid rgb(50,50,50);
}

.modal-content button {
  width: 200px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 18px;
}


.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: rgb(50,50,50);
  text-decoration: none;
  cursor: pointer;
}

form label, 
form input, 
form textarea {
  display: flex; /* Cela fera en sorte que chaque élément occupe une ligne entière, forçant le suivant à passer à la ligne suivante */
  margin-bottom: 10px; /* Ajoute un peu d'espace entre les éléments du formulaire */
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding: 10px;
}

form textarea {
  height: 200px;
}