.contact-container {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: rgb(240, 240, 240);
    justify-content: center;
}

.contact-container h4 {
    font-weight: 500;
    padding-bottom: 30px;
}