.screenshots_container {
    display: flex;
    flex-direction: column;
    border-top: 3px solid rgb(50,50,50);
    border-bottom: 3px solid rgb(50,50,50);
    /* background-color: rgba(175, 125, 250, 0.5); */
    background: linear-gradient(to bottom right, rgba(175, 125, 250, 0.5), rgba(175, 125, 250, 0.1));
    background-color: white;
    height: auto;
    width: 100%;
    text-align: center;
    justify-content: center;
    /* padding: 20px; */
    padding-top: 20px;
    padding-bottom: 20px;    
}

.screenshots_wrapper {
    display: flex;
    flex-direction: row;
    max-width: 1500px;
    width: 95%;
    align-self: center;
    /* background-color: yellow; */
}

.screenshots_container h1 {
    padding: 50px;
}

.horizontal_box_screenshots {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    justify-content: space-evenly;
    line-height: 50px;
    overflow-wrap: break-word;
    max-width: 1500px;
    /* background-color: red;
    margin: 5px; */
}
  
.left_box_screenshots {
    flex: 1;
    position: relative;
    border-radius: 8px;
    align-self: center;
    /* background-color: orange;
    margin: 5px; */
}

.Halyzia_description {
    line-height: 1.5;
    padding-top: 10px;
    text-align: justify;
    /* background-color: green;
    margin: 5px; */
}



.screenshots_gif_container {
    display: flex;
    flex-direction: column;
    border-top: 3px solid rgb(50,50,50);
    border-bottom: 3px solid rgb(50,50,50);
    /* background: linear-gradient(to bottom right, rgba(175, 125, 250, 0.5), rgba(175, 125, 250, 0.1));
    background-color: white; */
    background-color: #000;
    text-align: center;
    justify-content: center;
    height: 600px;

    display: flex;
    flex-direction: column;
    justify-content: center;  /* Centrer verticalement */
    align-items: center;  /* Centrer horizontalement */
   }

.screenshots_gif_text_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-self: center;

    margin: 50px;
    padding: 50px;
    border-radius: 8px;

}

.screenshots_gif_text_container h4 {
    color: rgb(50,50,50);
    text-align: center;
    line-height: 3;
    color: rgb(240,240,240);
    font-weight: 300;
}

.screenshots_gif_wrapper {
    max-width: 1500px;
    width: 100%;
    height: 100%;
    /* background-color: yellow; */

    display: flex;
    flex-direction: row;
    justify-content: center;  /* Centrer horizontalement */
    align-items: center;  /* Centrer verticalement */
}

.left_box_gif_screenshots {
    background-image: 
    /* url("https://i.gifer.com/3aNP.gif"); */
    url("../../../../public/images/carousel_sotisAI/7Ntk.gif");
    background-size: cover;
    height: 100%;

    flex: 1;
    display: flex;
    justify-content: center;  /* Centrer horizontalement */
    align-items: center;  /* Centrer verticalement */
    text-shadow: #fff 1px 0 5px;
}

/* Carousel */
.carousel {
    position: relative;
    width: 100%;
    height: 60vw;
    width: 100%;
    max-height: 900px;
  }
  
.carousel img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
    border: 1px solid rgb(50,50,50);
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
}

.arrow i {
    font-size: 24px;
    color: rgba(175,125,250,1);
    transition: all 0.3s ease-in-out;
}

/* .arrow:hover i {
    font-size: 32px;
} */

.arrow-left {
    left: 0;
    background-color: rgba(0,0,0,0.1);
}

.arrow-right {
    right: 0;
    background-color: rgba(0,0,0,0.1);
}

.arrow-left:hover,
.arrow-right:hover {
    background-color: rgba(0,0,0,0.2);    
}


@media only screen and (max-width: 1250px) {

    .screenshots_container h1 {
        padding: 40px;
    }
    
}

@media only screen and (max-width: 1000px) {

    .screenshots_container {
        padding-top: 30px;
        padding-bottom: 30px;
        height: auto;
    }

    .horizontal_box_screenshots {
        flex-direction: column;
    }

    .left_box_screenshots {
        align-self: stretch;
    }

    .screenshots_gif_container {
        height: auto;
    }

    .screenshots_gif_text_container {
        margin: 20px;
        padding: 20px;
    }
    
}

@media only screen and (max-width: 500px) {

    .screenshots_container {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .screenshots_container h1 {
        padding: 20px;
    }

    .screenshots_gif_text_container {
        margin: 5px;
        padding: 5px;
        text-align: justify;
    }

    .screenshots_gif_text_container h4{

        text-align: justify;
    }

}






