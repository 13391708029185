/*---------------------
    SECTION PARALLAX
  ---------------------*/

.parallax h2 {
  line-height: 3;
  letter-spacing: 1px;
  display: flex;
  justify-content: space-evenly;
  text-align: center;

}

.parallax {
  position: relative;
  background: rgba(255, 255, 255, 1);
  margin: 0px;
  text-align: center;
  border: none;
  min-height: 1800px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.parallax::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("/public/images/LG_imgs/lab_background_2.jpg") no-repeat center center fixed;
  background-size: cover;
  background-position: center;
  /* centre l'image de fond horizontalement et verticalement */
  opacity: 0.5;

}

.parallax-inner {
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: row;
  width: 90%;
  height: auto;
  /* Ensure the child can grow */
  min-height: 100%;
  /* Ensure it takes at least the full height of the parent */
  align-self: center;
  max-width: 100%;
  /* Ensure it does not exceed the width of the parent */
  max-height: 100%;
  /* Ensure it does not exceed the height of the parent */
  /*columns: 2;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);*/
}

.parallax h4 {
  font-weight: 500;
}

.parallax p {
  margin: 0;
  color: #333;
  text-align: justify;
}

.container-box-parallax {
  height: 100%;
  width: 100%;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  /*overflow: hidden;*/
  /*-ms-transform: translate(12.5%, 22.5%);
    transform: translate(12.5%, 22.5%);*/
}

.horizontal-box-parallax {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.vertical-box-parallax {
  /*border: 5px solid rgb(50,50,50);*/
  position: relative;
  display: flex;
  border-radius: 8px;
  padding: 5px;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  object-fit: center;
  height: auto;
}

.v-box-par-img {
  width: 70%;
}

.v-box-par-txt {
  width: 100%;
}

.text-box-parralax {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid rgb(50, 50, 50);
  height: 100%;
  width: 100%;
  text-align: left;
  /* overflow-y:scroll; */
  border-radius: 8px;
  /* display: flex;
    flex-direction: column;
    background-color: red;
    justify-content: center;
    overflow:visible; */
}

.text-item-parallax {
  width: 100%;
  padding: 20px;

}

/* .text-box-parralax:hover {
    background-color: rgb(254, 245, 255);
    cursor: pointer;
  } */

.text-little-box-parallax {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  width: 100%;
}

.text-little-box-parallax span {
  font-weight: 500;
  color: rgb(50, 50, 50);
}

.parallax img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border: 1px solid rgb(50, 50, 50);
  border-radius: 5px;
  background-color: #fff;
}

.parallax_button_wrapper {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1300px) {

  .parallax {
    padding-top: 50px;
    padding-bottom: 50px;
    left: 0px;
    min-height: auto;
    height: auto;
  }

  .parallax-inner {
    padding: 5px;
  }

  .v-box-par-img {
    width: 100%;
    height: 500px;
    max-height: 500px;
  }

  /* .vertical-box-parallax {
      flex: 1 3 auto;
    } */

  .horizontal-box-parallax {
    flex-direction: column;
  }

  .container-box-parallax {
    border: 0px solid rgb(50, 50, 50);
    background-color: transparent;
  }


  .text-box-parralax {
    height: 100%;
  }

}

@media screen and (max-width: 800px) {
  .parallax {
    left: 0px;
  }

  .v-box-par-img {
    width: 100%;
    height: 500px;
    max-height: 500px;
  }

  .parallax-inner {
    width: 100%;
    height: 100%;
  }

  .parallax-conclusion h4 {
    padding: 10px;
  }

}

@media screen and (max-width: 600px) {
  .parallax-conclusion h4 {
    font-size: 20px;
  }

  .parallax-conclusion a {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {

  .parallax-conclusion h4 {
    font-size: 18px;
    padding: 5px;
  }

  .parallax-conclusion a {
    font-size: 18px;
  }

  .horizontal-box-parallax {
    margin: 0;
    padding: 0;
  }

}