.testCards_container {
    display: flex;
    flex-direction: row;
    border-top: 3px solid rgb(50,50,50);
    background: linear-gradient(to bottom right, rgba(175, 125, 250, 0.5), rgba(175, 125, 250, 0.1));
    background-color: white;
    min-height: 900px;
    height: auto;
    max-height: auto;
    width: 100%;
    text-align: center;
    justify-content: center;
    padding: 0px;
    /* padding-top: 100px; */
    /* padding-bottom: 100px; */
    /* margin-top: 150px; */
    
}

.testCards_wrapper {
    display: flex;
    flex-direction: column;
    padding: 5px;
    max-width: 1500px;
    width: 100%;
    align-self: center;
}

/* Carousel */
.testimonials_carousel {
    display: flex;
    flex-direction: column;
    position: relative;
    /* max-width: 800px; */
    width: 800px;
    min-height: 800px;
    height: auto;
    align-items: center;
    align-self: center;
  }
  
.testimonials_individual_card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* justify-content: center; */
    margin: 10px;
    /* overflow: scroll; */
    border: 1px solid rgb(50,50,50);
    /* border-radius: 30px; */
    max-height: 5000px;
    background-color: white;
}

.testimonials_individual_card_image {
    position: relative; /* Ajout de la propriété position relative */
    display: flex;
    flex-direction: column;
    text-align: center;
    /* height: 500px; */
    padding: 30px;
    border-bottom: 1px solid rgb(50,50,50);
    padding-bottom: 30px;
    background-color: rgb(60,60,60);

}

.testimonials_individual_card_image img {
    width: 92px;
    height: 92px;
    border: 1px solid rgb(50,50,50);
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 30px;

}

.testimonials_individual_card_image a {
    width: 92px;
    align-self: center;
}

.testimonials_individual_card_image p {
    display: flex;
    color: rgb(240,240,240);
    font-size: 20px;
    /* background-color: red; */
    align-self: center;

}

.testimonials_individual_card_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    height: 100%;
    /* overflow: scroll; */
    /* border-bottom-left-radius: 30px; */
    /* border-bottom-right-radius: 30px; */
}

.testimonials_individual_card_text {
    transition: background-color 0.3s ease;
    transition: opacity 0.9s ease;
}


.testimonials_individual_card_text p {
    font-size: 16px;
    padding: 30px;

    /* text-align: justify; */
}

.card-title {
    font-weight: 600;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.arrow i {
    font-size: 24px;
    color: rgba(175,125,250,1);
    transition: all 0.3s ease-in-out;
}

.arrow-testimonials i {
    color: rgba(175,125,250,1);
    cursor: pointer;

}

.to-left{
    color:white;
    padding-top:1.8em;
    display:inline-block;/* or block */
    position:relative;
    border-color:white;
    text-decoration:none;
    transition:all .3s ease-out;
}

.to-right{
    color:white;
    padding-top:1.8em;
    display:inline-block;/* or block */
    position:relative;
    border-color:white;
    text-decoration:none;
    transition:all .3s ease-out;
}

.to-left:before{
    content:'◀';
    font-size:.9em;
    position:absolute;
    top:0;
    left:50%;
    margin-left:-.7em;
    border:solid .13em white;
    border-radius:10em;
    width:1.4em;
    height:1.4em;
    line-height:1.4em;
    letter-spacing: 4px;
    border-color:inherit;
    transition:transform .5s ease-in;
}

.to-right:before{
    content:'▶';
    font-size:.9em;
    position:absolute;
    top:0;
    left:50%;
    margin-left:-.9em;
    border:solid .13em white;
    border-radius:10em;
    width:1.4em;
    height:1.4em;
    line-height:1.4em;
    letter-spacing: -4px;
    border-color:inherit;
    transition:transform .5s ease-in;
}

.to-right:hover,
.to-left:hover{
    color: #fff;
    border-color:rgba(175,125,250,1);
}

.to-left:hover:before{
    transform: rotate(360deg);
}

.to-right:hover:before{
    transform: rotate(360deg);
}

/**********/

/* .testimonials_individual_card_image .arrow.arrow-left {
    position: absolute;
    top: 40px; 
    left: 60px;
  }
  
  .testimonials_individual_card_image .arrow.arrow-right {
    position: absolute;
    top: 40px; 
    right: 60px; 
  }
  
  .testimonials_individual_card_image .arrow-container .arrow {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  .testimonials_individual_card_image .arrow-container .arrow.hover-color-1:hover {
    background-color: rgba(0,0,0,0); 
  }
  
  .testimonials_individual_card_image .arrow-container .arrow.hover-color-2:hover {
    background-color: rgba(0,0,0,0); 
  } */

  .testimonials_individual_card_image .arrow-container .arrow.hover-color-1:hover {
    background-color: rgba(0,0,0,0); 
    cursor: default;
  }

  .testimonials_individual_card_image .arrow-container .arrow.hover-color-2:hover {
    background-color: rgba(0,0,0,0); 
    cursor: default;
  }

/* Progress bar */
.progress-bar {
    background-color: #fff;
    border-radius: 5px;
    height: 5px;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
    margin-top: 5px;
    z-index: 10;
}
.progress-bar-fill {
    background-color: rgba(175,125,250,0.5);
    height: 100%;
    border-radius: 5px;
    transition: width 0.25s ease-in-out;
}

.btn_testimonials_container {
    background-color: red;
    width: 100%;
}


@media only screen and (max-width: 800px) {

    .testCards_container {
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;        
    }

    .testCards_wrapper {
        height: 'auto';
        margin: 0px;
    }

    .testimonials_carousel {
        height: 100%;
        width: 100%;
    }

    .testimonials_individual_card_text p {    
        text-align: justify;
    }

    /* .to-left:before{
        margin-left: -50px;
    }

    .to-right:before{
        margin-left: 15px;
    } */

    /* .testimonials_individual_card_image {
        height: 400px;

    }

    .testimonials_individual_card_text {
        height: 70%;
    } */

}

@media only screen and (max-width: 500px) {

    .testimonials_carousel img {
        width: 64px;
        height: 64px;
    }

}

@media only screen and (max-width: 400px) {

    /* .testCards_container {
        height: 1200px;
    } */ 

    .testimonials_individual_card_text p {
        padding: 10px;
    }
}


