/*---------------------
  SECTION INTRO
---------------------*/

.intro_container {
  justify-content: space-evenly; /* center */
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: rgb(240, 240, 240);
  border-top: 3px solid rgb(50,50,50);
  /*background: linear-gradient(rgb(240,240,240), rgb(255,255,255));*/
  background-blend-mode: screen;  
  padding-top: 100px;
}

.text_container {
  display: flex;
  justify-content: space-evenly;
  align-self: center;
  max-width: 1500px;
  border-radius: 10px;
  background-color: rgb(240,240,240);
  /* margin: 50px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 6px 50px rgba(0, 0, 0, 0.17);
  -webkit-filter: drop-shadow(0 6px 50px rgba(0, 0, 0, 0.017));
  filter: drop-shadow(0 6px 50px rgba(0, 0, 0, 0.017)); */
}

.intro_text {
  overflow: hidden;
  overflow: hidden;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.intro_text h1 {
  /* text-align: justify; */
  text-align: center;
  width: 90%;
}

.intro_container p  {
  padding: 50px;
}

.moving_text {
  /*font-size: 25px;*/
  line-height: 1;
  letter-spacing: 1px;
  font-size: 24px;
  padding: 7px;
}

.moving_text span {
  /*font-size: 25px;*/
  line-height: 1;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 24px;
  width: 80%;
  color: rgb(50,50,50);
}

@media only screen and (max-width: 1000px) {
  .moving_text span {
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {

  .moving_text span {
    font-size: 18px;
  }

  .text_container {
    margin: 10px;
    padding: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .intro_text h1 {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: justify;
  }

  .intro_text p {
    padding: 0;
    width: 100%;
    text-align: justify;
  }

}