/*---------------------
  SECTION PARTENAIRES
---------------------*/
/*
:root {
  --color-text: navy;
  --color-bg: papayawhip;
  --color-bg-accent: #ecdcc0;
  --size: clamp(10rem, 1rem + 40vmin, 30rem);
  --gap: calc(var(--size) / 14);
  --duration: 60s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}
*/

:root {
    --color-text: navy;
    --color-bg: #333;
    --color-bg-accent: #fff; /*linear-gradient(#b3b3b3, white, white, #b3b3b3);*/
    --size: clamp(10rem, 1rem + 20vmin, 30rem);
    --gap: calc(var(--size) / 14);
    --duration: 60s;
    --scroll-start: 0;
    --scroll-end: calc(-100% - var(--gap));
  }
  
  @media (prefers-color-scheme: dark) {
    :root {
      --color-text: papayawhip;
      --color-bg: navy;
      --color-bg-accent: #2626a0;
    }
  }
  
  * {
    box-sizing: border-box;
  }
  
  #partners_section {
    display: grid;
    align-content: center;
    overflow: hidden;
    gap: var(--gap);
    width: 100%;
    font-family: system-ui, sans-serif;
    font-size: 1rem;
    border-top: 3px solid rgb(50,50,50);
    background-color: #fff;
  
    /*color: var(--color-text);
    background-color: var(--color-bg);*/
  }
  
  .marquee {
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
    mask-image: linear-gradient(
      var(--mask-direction, to right),
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 20%,
      hsl(0 0% 0% / 1) 80%,
      hsl(0 0% 0% / 0)
    );
  }
  
  .marquee_group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll-x var(--duration) linear infinite;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .marquee_group {
      animation-play-state: paused;
    }
  }
  
  .marquee--vertical {
    --mask-direction: to bottom;
  }
  
  .marquee--vertical,
  .marquee--vertical .marquee_group {
    flex-direction: column;
  }
  
  .marquee--vertical .marquee_group {
    animation-name: scroll-y;
  }
  
  .marquee--reverse .marquee_group {
    animation-direction: reverse;
    animation-delay: -3s;
  }
  
  @keyframes scroll-x {
    from {
      transform: translateX(var(--scroll-start));
    }
    to {
      transform: translateX(var(--scroll-end));
    }
  }
  
  @keyframes scroll-y {
    from {
      transform: translateY(var(--scroll-start));
    }
    to {
      transform: translateY(var(--scroll-end));
    }
  }
  
  /* Element styles */
  .marquee img {
    display: grid;
    place-items: center;
    width: var(--size);
    fill: var(--color-text);
    background: var(--color-bg-accent);
    aspect-ratio: 16/9;
    padding: calc(var(--size) / 10);
    border-radius: 0.5rem;
  }
  
  .marquee--vertical img {
    aspect-ratio: 1;
    width: calc(var(--size) / 1.5);
    padding: calc(var(--size) / 6);
  }
  
  /* Parent wrapper */
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    margin: auto;
    max-width: 100vw;
  }
  
  .wrapper--vertical {
    flex-direction: row;
    height: 100vh;
  }
  
  /* Toggle direction button */
  .toggle {
    --size: 3rem;
    position: relative;
    top: 1rem;
    left: 1rem;
    width: var(--size);
    height: var(--size);
    font: inherit;
    text-align: center;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 50%;
    color: inherit;
    background-color: white;
    z-index: 1;
  }
  
  .toggle:focus-visible {
    box-shadow: 0 0 0 2px var(--color-text);
  }
  
  .toggle span {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: calc(100% + 0.4em);
    width: fit-content;
    white-space: nowrap;
    transform: translateY(-50%);
    animation: fade 400ms 4s ease-out forwards;
    user-select: none;
    color: white;
  }
  
  .toggle svg {
    --size: 1.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--size);
    height: var(--size);
    fill: currentcolor;
    transform: translate(-50%, -50%);
    transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1);
    color: #333;
  }
  
  .toggle--vertical svg {
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  
  @keyframes fade {
    to {
      opacity: 0;
      visibility: hidden;
    }
  }
  